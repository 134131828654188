import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import SignUpContext from '@jsv2/context/SignUpContext';
import { detectPremiumMembership } from '@jsv2/utils/flowUtils';
import TermsConditionsModal from '@jsv2/components/Modals/TermsConditionsModal';
import CreditCardDataInput from './CreditCardDataInput';

const AddCreditCardForm = ({ showNameOnCardField, onClose, onSubmit, errors }) => {
  const { isDepositFlow, signUpProcess, urlParamsData } = useContext(SignUpContext);
  const { flow, membershipSummary } = signUpProcess;
  const { promoCampaignId, name, language } = membershipSummary;
  const inputRef = useRef(null);
  const [isChecked, toggleChecked] = useState(true);
  const [isOpen, toggleOpen] = useState(false);

  const decodedUrlParamsData = urlParamsData ? JSON.parse(atob(urlParamsData)) : {};

  const getPaymentData = () => {
    const cardData = inputRef.current.getData();
    // eslint-disable-next-line camelcase
    const card_exp_year =
      +cardData.expirationYear < 100 ? `20${cardData.expirationYear}` : cardData.expirationYear;
    cardData.save_method = true;

    Object.assign(
      cardData,
      { card_exp_year },
      { promo_campaign_id: promoCampaignId },
      { signup_flow: flow },
      { is_deposit_flow: isDepositFlow },
      { plan_a_trip_quiz: decodedUrlParamsData },
    );

    return cardData;
  };

  return (
    <>
      <div className="adding-credit-card" data-qa-id="adding-credit-card">
        <CreditCardDataInput
          errors={errors}
          visible
          ref={inputRef}
          showNameOnCardField={showNameOnCardField}
        />

        <div className="terms-and-conditions custom-checkbox">
          <input
            id="terms-and-conditions-check"
            type="checkbox"
            checked={isChecked}
            onChange={() => toggleChecked(!isChecked)}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
          <label htmlFor="terms-and-conditions-check">
            <div>I accept the&nbsp;</div>
          </label>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="button-link"
            onClick={() => toggleOpen(true)}
            data-qa-id="accept_checkbox"
          >
            terms and conditions
          </div>
        </div>
      </div>

      <div className="content-footer text-center">
        {onClose && (
          <button
            onClick={() => onClose()}
            type="button"
            className="mr-10"
            data-qa-id="cancel-button"
          >
            {t('Cancel')}
          </button>
        )}

        <button
          onClick={() => onSubmit(getPaymentData())}
          type="button"
          className="button-pink"
          disabled={!isChecked}
          data-qa-id="join_vip_traveler_button"
        >
          {language.cta || (detectPremiumMembership(flow) ? 'Get VIP+ Membership' : name)}
        </button>
      </div>

      <TermsConditionsModal isOpen={isOpen} closeModal={() => toggleOpen(false)} />
    </>
  );
};

AddCreditCardForm.propTypes = {
  showNameOnCardField: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

AddCreditCardForm.defaultProps = {
  showNameOnCardField: false,
  onClose: null,
};

export default AddCreditCardForm;
