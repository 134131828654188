import React, { useContext, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import Cookies from 'browser-cookies';

import { MOBILE_SCREEN_WIDTH, TABLET_SCREEN_WIDTH } from '@js/constants';
import { isRegistered, getMembershipSummary } from '@jsv2/utils/UserUtils/userStatuses';
import OAuthUserEmail from '@components/Auth/OAuthUserEmail';
import { updatePageSections } from '@jsv2/utils/PageUtils/PageAPIRequests';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import { EditFieldContextProvider } from '@jsv2/LandingPage/context/EditFieldContext';
import UserContext from '@jsv2/context/UserContext';
import SignUpContext from '@jsv2/context/SignUpContext';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';

import SIGNUP_FLOW from '@jsv2/config/SignUp/flows';
import { getUserRoles } from '@jsv2/utils/UserUtils/APIRequest';
import { detectPremiumMembership } from '@jsv2/utils/flowUtils';
import { isFacebookUrl } from '@jsv3/utils/urlUtils';
import { promoCampaignListAPI } from '@jsv3/utils/api/promoCampaignListAPI';

import EditPopup from '@jsv2/LandingPage/components/EditPopup';
import EditImagePopup from '@jsv2/LandingPage/components/EditImagePopup';
import AudioButton from '@js/landing/components/AudioButton';
import User from '@jsv2/models/User';
import SignUpProcess from '@jsv2/models/SignUpProcess';
import SignUpModal from '@jsv2/components/Modals/SignUpModal';
import LpBuilder from '@jsv2/LandingPage/LPBuilder';
import Footer from '@components/Footer';
import Header from '@jsv2/LandingPage/components/Header';

const { user, userRoles, templateName, location, isPreviewPage, pageSections, pageId } = window;

const queryString = location.search;
const urlParams = new URLSearchParams(queryString);
// Take the "go_back_url" parameter from the current url
const goBackUrl = urlParams.get('go_back_url');
const quizParams = urlParams.get('quizParams');

const templatesWithoutBanner = [
  'membershipChoosePlan',
  'oneOfBooking',
  'depositFLow',
  'planATrip',
  'planATripQuiz',
];

const templatesWithoutSignUp = ['planATripQuiz'];

const Landing = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const isUpgradePlanTemplate = templateName === 'membershipUpgradePlan';
  const isDepositTemplate = templateName === 'depositFLow';
  const isPlanATripQuizTemplate = templateName === 'planATripQuiz';
  const isPlanATripTemplate = templateName === 'planATrip';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTopMenuVisible, setIsTopMenuVisible] = useState(false);
  const [isCurtainVisible, setIsCurtainVisible] = useState(false);
  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const [customer, setCustomer] = useState(new User(user, userRoles));
  const [needHideStickyBtn, setNeedHideStickyBtn] = useState(false);
  const [signUpProcess, setSignUpProcess] = useState({});
  const [config, setConfig] = useState([]);
  const [isEditSectionMode, setIsEditSectionMode] = useState(isPreviewPage);
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [quizAnswers, setQuizAnswers] = useState([]);

  const headerRef = useRef(null);
  const audioRef = useRef(null);

  const isTemplateHasBanner = !templatesWithoutBanner.includes(templateName);
  const isTemplateHasSignUp = !templatesWithoutSignUp.includes(templateName);

  const logInUrl = quizParams
    ? `/sign-in?go_back_url=${btoa(JSON.stringify(`${location.pathname + location.search}`))}`
    : '/sign-in';

  /**
   * @param {object} currentUser
   * @return {void}
   */
  const onEmailConfirm = (currentUser) => {
    window.user = currentUser;

    setIsModalOpen(true);
  };

  /**
   * @return {void}
   */
  const openModal = () => {
    setIsAudioPlay(false);

    const screenWidth = document.documentElement.clientWidth;

    if (screenWidth < MOBILE_SCREEN_WIDTH) {
      setIsCurtainVisible(false);
    }

    setIsModalOpen(true);
    setIsTopMenuVisible(false);
  };

  /**
   * Show / hide top menu
   *
   * @return {void}
   */
  const toggleTopMenu = () => {
    const screenWidth = document.documentElement.clientWidth;

    if (screenWidth < TABLET_SCREEN_WIDTH) {
      setIsTopMenuVisible((prevState) => !prevState);
    }
  };

  /**
   * Update sign up data
   *
   * @param updatedValues (flow && membershipSummary) {Object}
   */
  const updateSignUpData = (updatedValues) => {
    setSignUpProcess(
      new SignUpProcess({
        ...updatedValues,
        userRoles,
      }),
    );
  };

  /**
   * Update user data
   *
   * @param key {string}
   * @param value {any}
   * @return {void}
   */
  const updateUserData = (key, value) => {
    setCustomer((prevState) => ({ ...prevState, [key]: value }));
  };

  /**
   * Update user data
   *
   * @return {Promise}
   */
  const updateUserRoles = () =>
    getUserRoles().then(({ data }) => {
      updateUserData('roles', data);
    });

  /**
   * Scroll to element by id
   *
   * @param id {string}
   * @return {void}
   */
  const scrollTo = (id) => {
    const section = document.getElementById(id);
    const yOffset = headerRef.current.getBoundingClientRect().height;
    const sectionTopBoundary = section.getBoundingClientRect().top;
    const sectionPosition = sectionTopBoundary + window.pageYOffset - yOffset;

    window.scrollTo({ behavior: 'smooth', top: sectionPosition });
  };

  /**
   * Scroll to next visible section
   *
   * @param nextSectionName {string}
   * @return {void}
   */
  const scrollToNextSection = (nextSectionName) => {
    const bannerSection = config.find((section) => section.props.nextSection === nextSectionName);
    const currentNextSection = config.find(({ name }) => name === nextSectionName);

    if (currentNextSection.isVisible && currentNextSection.order > bannerSection.order) {
      scrollTo(nextSectionName);

      return;
    }

    const filteredConfig = config.filter(
      ({ isVisible, order }) =>
        isVisible && order > bannerSection.order && order > currentNextSection.order,
    );

    if (filteredConfig[0]) {
      scrollTo(filteredConfig[0].name);
    }
  };

  /**
   * Handler for "join" button
   *
   * @return {void}
   */
  const onClickJoinButtonHandler = () => {
    const idToScroll = 'choosePlanSection';
    const sectionToScroll = document.getElementById(idToScroll);

    if (
      membershipOptions &&
      membershipOptions.length > 1 &&
      !isRegistered(customer.roles) &&
      sectionToScroll
    ) {
      scrollTo(idToScroll);

      return;
    }

    openModal();
  };

  /**
   * Render "become" button
   *
   * @param btnClass {string}
   * @param btnText {string}
   * @param dataQaId {string}
   * @param onRequestCloseModal {function || null}
   * @return {JSXElement}
   */
  const renderBecomeButton = (btnClass, btnText, dataQaId, onRequestCloseModal) => {
    const onClickHandler = () => {
      if (onRequestCloseModal) {
        onRequestCloseModal();

        setTimeout(() => {
          onClickJoinButtonHandler();
        });
      } else {
        onClickJoinButtonHandler();
      }
    };

    return (
      <div className="become-btn-wrapper">
        <button type="button" className={btnClass} onClick={onClickHandler} data-qa-id={dataQaId}>
          {t(`${btnText}`)}
        </button>
      </div>
    );
  };

  /**
   * Render "login" button
   *
   * @param  className {string}
   * @return {JSXElement}
   */
  const renderLoginButton = (className) => (
    <a className={classNames('btn-white', className)} href={logInUrl} data-qa-id="header-login">
      {t('Login')}
    </a>
  );

  /**
   * Render "join us" button
   *
   * @param  className {string}
   * @return {JSX.Element}
   */
  const renderJoinUsButton = (className) => {
    const defaultTitle = window.templateVariables.header_cta_text?.content;

    if (isPlanATripQuizTemplate) {
      const idToScroll = 'quizSection';

      return (
        <button
          type="button"
          className={classNames(isUpgradePlanTemplate ? 'btn-red' : 'btn-black', className)}
          onClick={() => scrollTo(idToScroll)}
          data-qa-id="qa_header_get_started"
        >
          {defaultTitle}
        </button>
      );
    }

    const qaId = !isRegistered(customer.roles)
      ? 'become-join-btn'
      : 'header-join-us-finish-sign-up';

    const title = isRegistered(customer.roles) ? 'finish sign up' : defaultTitle;

    return (
      <button
        type="button"
        className={classNames(isUpgradePlanTemplate ? 'btn-red' : 'btn-black', className)}
        onClick={onClickJoinButtonHandler}
        data-qa-id={qaId}
      >
        {isUpgradePlanTemplate ? defaultTitle : title}
      </button>
    );
  };

  /**
   * Check if url is facebook url
   *
   * @return {void}
   */
  const checkUrl = () => {
    if (isFacebookUrl()) {
      Cookies.set('userFromFacebook', 'true');
    }
  };

  /**
   * Move banner position
   *
   * @return {void}
   */
  const toggleBannerPosition = () => {
    const scrollHeight = window.pageYOffset;
    const bannerHeight = document.documentElement.clientHeight;

    if (scrollHeight < bannerHeight && !isCurtainVisible) {
      setIsCurtainVisible(true);
    }

    if (scrollHeight > bannerHeight && isCurtainVisible) {
      setIsCurtainVisible(false);
    }
  };

  /**
   * close modal
   *
   * @return {void}
   */
  const closeModal = () => {
    toggleBannerPosition();

    setIsModalOpen(false);
  };

  /**
   * Show/hide sticky "join btn"
   *
   * @param topPicksSection {HTMLElement}
   * @return {void}
   */
  const toggleStickyJoinBtn = (topPicksSection) => {
    const { pageYOffset, innerHeight } = window;
    const topLine = topPicksSection.offsetTop - innerHeight;
    const bottomLine = topLine + topPicksSection.clientHeight;

    if ((pageYOffset < topLine || pageYOffset > bottomLine) && needHideStickyBtn) {
      setNeedHideStickyBtn(false);
    }

    if (pageYOffset > topLine && pageYOffset < bottomLine && !needHideStickyBtn) {
      setNeedHideStickyBtn(true);
    }
  };

  /**
   * Scroll handler
   *
   * @return {void}
   */
  const scrollHandler = () => {
    const topPicksSection = document.getElementById('topPicks');

    toggleBannerPosition();

    if (isMobile(screenTypeContext) && topPicksSection) {
      toggleStickyJoinBtn(topPicksSection);
    }
  };

  /**
   * Update config
   *
   * @param updatedConfig {Array}
   * @return {void}
   */
  const updateConfig = (updatedConfig) => {
    setConfig(updatedConfig);
    updatePageSections(updatedConfig, urlParams.get('access_token'));
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, { passive: true });

    return () => {
      window.removeEventListener('scroll', scrollHandler, { passive: true });
    };
  }, [needHideStickyBtn, isCurtainVisible]);

  useEffect(() => {
    setConfig(pageSections);

    promoCampaignListAPI(pageId).then((data) => {
      const promoCampaigns = data && Object.keys(data).length > 0 ? data.data : [];
      const summary = getMembershipSummary(promoCampaigns);

      setMembershipOptions(promoCampaigns);
      setSignUpProcess(
        new SignUpProcess({
          flow: isDepositTemplate ? SIGNUP_FLOW.DEPOSIT_FLOW : summary.flow,
          membershipSummary: summary,
          userRoles,
        }),
      );
    });

    checkUrl();

    scrollHandler();

    if (isDepositTemplate) {
      window.questionnaireFilters = { type: 'plan_a_trip_survey' };
    }
  }, []);

  if (Object.keys(signUpProcess).length === 0) {
    return null;
  }

  return (
    <LPContext.Provider
      value={{
        isAudioPlay,
        openModal,
        toggleAudioPlayback: setIsAudioPlay,
        renderBecomeButton,
        hasPremiumMembership: detectPremiumMembership(signUpProcess.flow),
        scrollTo,
        scrollToNextSection,
        config,
        updateConfig,
        isEditSectionMode,
        setIsEditSectionMode,
      }}
    >
      <UserContext.Provider
        value={{
          customer,
          updateUserData,
          updateUserRoles,
          setQuizAnswers,
          quizAnswers,
        }}
      >
        <SignUpContext.Provider
          value={{
            signUpProcess,
            membershipOptions,
            urlParamsData: quizParams,
            modalIsOpen: isModalOpen,
            closeModal,
            openModal,
            updateSignUpData,
            isPlanATripTemplate,
            logInUrl,
          }}
        >
          <EditFieldContextProvider>
            <div className="vt-common-promo-landing" data-qa-id="qa_promo_landing">
              <OAuthUserEmail onSuccess={onEmailConfirm} />

              {isTemplateHasBanner && (
                <AudioButton
                  ref={(node) => (audioRef.current = node)}
                  url={['/images/lp/promo3/audio-landing.mp3']}
                  isAudioPlay={isAudioPlay}
                  toggleAudioPlayback={setIsAudioPlay}
                />
              )}

              <Header
                ref={headerRef}
                onClickMenu={toggleTopMenu}
                topMenuIsVisible={isTopMenuVisible}
                isCurtainVisible={isCurtainVisible}
                isHeaderHidden={isModalOpen}
                loginButton={renderLoginButton}
                joinUsButton={renderJoinUsButton}
                goBackUrl={goBackUrl}
                config={config}
                allowedHeaderInvert={isTemplateHasBanner}
              />

              <LpBuilder config={config} isEditSectionMode={isEditSectionMode} />

              {isMobile(screenTypeContext) &&
                !needHideStickyBtn &&
                renderJoinUsButton('sticky-btn')}
            </div>

            <Footer links={window.__SERVER_DATA__.shared_data.navigation} />

            {isPreviewPage && <EditPopup />}
            {isPreviewPage && <EditImagePopup />}
          </EditFieldContextProvider>

          {isTemplateHasSignUp && <SignUpModal />}
        </SignUpContext.Provider>
      </UserContext.Provider>
    </LPContext.Provider>
  );
};

export default Landing;
